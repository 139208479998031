import axios from "axios";
import Cookies from "js-cookie";
import React, { Suspense } from "react";
import { useState, useEffect, useRef } from "react";
// import jwt_decode from "jsonwebtoken/decode";
import { Routes, Route, useLocation, Navigate } from "react-router-dom";

import { useAuth0 } from "@auth0/auth0-react";

// @mui material components
import { ThemeProvider } from "@mui/material/styles";
import CssBaseline from "@mui/material/CssBaseline";
import Icon from "@mui/material/Icon";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import TextField from "@mui/material/TextField";
import { Grid } from "@mui/material";

import MDBox from "components/MDBox";
import CustomLoading from "components/CustomComponents/CustomLoading";
import CustomSnackbarAlerts from "components/CustomComponents/CustomSnackbarAlerts";
// Material Dashboard 2 PRO React examples
import Sidenav from "examples/Sidenav";
// import Configurator from "examples/Configurator";

// Material Dashboard 2 PRO React themes
import theme from "assets/theme";

// Material Dashboard 2 PRO React Dark Mode themes
import themeDark from "assets/theme-dark";

// Images
import brandWhite from "assets/images/sptech-logos/sptech-logo-200x78px.webp";

// Material Dashboard 2 PRO React routes
import routes from "routes";
import { pageRoutes } from "routes";

// Material Dashboard 2 PRO React contexts
import { useMaterialUIController, setMiniSidenav, setSidenavColor } from "context";
import { setTransparentSidenav, setWhiteSidenav, setDarkMode } from "context";
import MDTypography from "components/MDTypography";
import MDButton from "components/MDButton";
// import { setOpenConfigurator } from "context";

// import MFAVerificationDialog from "views/multiFactorAuth/MFAVerificationDialog";
// import MFASetupDialog from "views/multiFactorAuth/MFASetupDialog";

const renderLoader = () => <CustomLoading />;

export default function App() {
  // create a ref to pass
  const childRef = useRef();
  // import functions from childRef
  const errorAlert = (error) => childRef.current?.errorAlert(error);
  const customAlert = (message, severity) => childRef.current?.customAlert(message, severity);
  // const navigate = useNavigate();
  const { isLoading, user, loginWithRedirect, getAccessTokenSilently, getIdTokenClaims } =
    useAuth0();
  const [controller, dispatch] = useMaterialUIController();
  const [userHomepage, setUserHomepage] = useState(process.env.REACT_APP_HOMEPAGE);
  const [userPerms, setUserPerms] = useState([]);
  const [mfaSessionId, setMFASessionId] = useState(null);
  const [isMFASessionIdLoading, setMFASessionIdLoading] = useState(true);
  const [otp, setOTP] = useState("");
  const [accessToken, setAccessToken] = useState(null);
  const [secret, setSecret] = useState("");
  const [imageUrl, setImageUrl] = useState("");
  const [isMFAActive, setIsMFAActive] = useState(false);
  const [isMFAForced, setIsMFAForced] = useState(false);
  // const [openVerifyOTPDialog, setOpenVerifyOTPDialog] = useState(true);
  // const [loading, setLoading] = useState(true);
  // const [isCookieReset, setIsCookieReset] = useState(false);

  const {
    miniSidenav,
    direction,
    layout,
    sidenavColor,
    transparentSidenav,
    whiteSidenav,
    darkMode,
  } = controller;
  // const { openConfigurator } = controller;

  /**
   * On every page refresh, auth0 is called to verify user login
   * A cookie "redirectUri" is created with the current path and user is redirected to auth0 /authorize
   * auth0 will return user to the origin url (ex: https://localhost/)
   * use the stored cookie to navigate to the path and after page load delete cookie
   */
  const redirectUri = Cookies.get("redirectUri");
  const [onMouseEnter, setOnMouseEnter] = useState(false);
  const { pathname } = useLocation();
  const brandName = "SPTECH PTY LTD";
  let client = null;

  try {
    if (pathname.split("/").slice(1)[0] === "clients") client = pathname.split("/").slice(1)[1];
  } catch (error) {
    // failed to get client id
  }

  const handleDarkMode = () => {
    // Set Dark / Light Sidenav
    if (darkMode) {
      // white Sidenav
      Cookies.set("darkMode", "false", { secure: true, sameSite: "strict" });
      setWhiteSidenav(dispatch, true);
      // setTransparentSidenav(dispatch, false);
    } else {
      // dark Sidenav
      Cookies.set("darkMode", "true", { secure: true, sameSite: "strict" });
      setWhiteSidenav(dispatch, false);
      setTransparentSidenav(dispatch, false);
    }
    // Toggle Dark / Light Theme
    setDarkMode(dispatch, !darkMode);
  };

  const handleOnMouseEnter = () => {
    if (miniSidenav && !onMouseEnter) {
      setMiniSidenav(dispatch, false);
      setOnMouseEnter(true);
    }
  };

  // Close sidenav when mouse leave mini sidenav
  const handleOnMouseLeave = () => {
    if (onMouseEnter) {
      setMiniSidenav(dispatch, true);
      setOnMouseEnter(false);
    }
  };
  // Change the openConfigurator state
  // const handleConfiguratorOpen = () => setOpenConfigurator(dispatch, !openConfigurator);

  // Setting the dir attribute for the body element
  useEffect(() => {
    document.body.setAttribute("dir", direction);
  }, [direction]);

  useEffect(() => {
    let mounted = true;

    const login = async () => {
      await loginWithRedirect();
    };

    const getAccesToken = async () => {
      const apiAccessToken = await getAccessTokenSilently();
      setAccessToken(apiAccessToken);
    };

    const fetchMFAStatus = async () => {
      // setLoading(true);
      // Check if MFA is active and if the user is forced to enable MFA
      try {
        // get MFA Session ID if available and prompt MFA setup/verification
        if (!Cookies.get("mfaSessionId")) {
          const response = await axios.get(
            `${process.env.REACT_APP_APPSPACE_API}/admin/appspace-users/mfa/active-and-forced`,
            {
              headers: {
                authorization: "Bearer " + accessToken,
                "Content-Type": "application/json",
              },
            }
          );
          const res = await axios.get(
            `${process.env.REACT_APP_APPSPACE_API}/admin/appspace-users/mfa/session-id`,
            {
              headers: {
                authorization: "Bearer " + accessToken,
                "Content-Type": "application/json",
              },
            }
          );
          if (res?.data?.mfaSessionId) {
            Cookies.set("mfaSessionId", res.data.mfaSessionId, {
              secure: true,
              sameSite: "strict",
            });
            setMFASessionId(res.data.mfaSessionId);
            setIsMFAActive(response.data.isMFAActive);
            setIsMFAForced(response.data.isMFAForced);
          } else {
            setIsMFAActive(response.data.isMFAActive);
            setIsMFAForced(response.data.isMFAForced);
          }
          if (!response.data.isMFAActive && response.data.isMFAForced && !imageUrl) {
            await setupTwoFactor();
          }
        }
        // setLoading(false);
      } catch (error) {
        console.error("Error fetching MFA status", error);
        errorAlert(error);
      } finally {
        setMFASessionIdLoading(false);
      }
    };

    const resetMFACookie = async () => {
      const idTokenClaims = await getIdTokenClaims();
      const mfaSessionIdCookie = Cookies.get("mfaSessionId");
      if (idTokenClaims?.sid && mfaSessionIdCookie && idTokenClaims?.sid !== mfaSessionIdCookie) {
        try {
          Cookies.remove("mfaSessionId");
          setMFASessionId(null);
          await axios.post(
            `${process.env.REACT_APP_APPSPACE_API}/admin/appspace-users/mfa/session-id`,
            { mfaSessionId: null },
            {
              headers: {
                authorization: "Bearer " + accessToken,
                "Content-Type": "application/json",
              },
            }
          );
        } catch (error) {
          console.error(error);
        }
      } else if (idTokenClaims?.sid && idTokenClaims?.sid === mfaSessionIdCookie && !mfaSessionId) {
        setMFASessionId(mfaSessionIdCookie);
        setMFASessionIdLoading(false);
      }
      await fetchMFAStatus();
    };

    if (mounted && !isLoading) {
      if (!user) login();
      else if (!accessToken) getAccesToken();
      else {
        // removes the MFA session id cookie on new login and adds the new MFA session id cookie
        resetMFACookie();
      }
    }

    return () => (mounted = false);
  }, [isLoading, accessToken]);

  useEffect(() => {
    const getUserPermissions = async () => {
      try {
        const idTokenClaims = await getIdTokenClaims();
        if (
          (idTokenClaims?.sid && mfaSessionId && idTokenClaims?.sid === mfaSessionId) ||
          (!isMFAActive && !isMFAForced)
        ) {
          const res = await axios.get(
            `${process.env.REACT_APP_APPSPACE_API}/admin/auth0-management-api/user-permissions`,
            {
              headers: {
                authorization: "Bearer " + accessToken,
                "Content-Type": "application/json",
              },
            }
          );
          setUserPerms(res.data.permissions ?? ["no_permissions"]);
          localStorage.setItem("userPerms", res.data.permissions?.toString() ?? "no_permissions");
        }
      } catch (error) {
        console.error(error);
        errorAlert(error);
      }
    };

    if (!isLoading && user && accessToken) {
      if (mfaSessionId || (!isMFAActive && !isMFAForced)) {
        const perms = localStorage.getItem("userPerms");
        if (typeof perms !== "string") {
          getUserPermissions();
        } else if (userPerms.length === 0) {
          setUserPerms(perms.split(",") ?? ["no_permissions"]);
        }
      }
    }
  }, [isLoading, accessToken, userPerms, mfaSessionId]);

  // Setting page scroll to 0 when changing the route
  useEffect(() => {
    const setTheme = () => {
      if (Cookies.get("darkMode") === "true" && !darkMode) {
        // set darkMode to true
        setWhiteSidenav(dispatch, false);
        setTransparentSidenav(dispatch, false);
        setDarkMode(dispatch, !darkMode);
      } else if (Cookies.get("darkMode") === "false" && darkMode) {
        // set darkMode to false
        setWhiteSidenav(dispatch, true);
        setDarkMode(dispatch, !darkMode);
      }
    };

    const setTitle = () => {
      try {
        const route = pathname.split("/").slice(1);
        let titlePart2 = route
          .slice(-1)[0]
          .split("-")
          .map((s) => s[0].toUpperCase() + s.substring(1));
        if (route[0].toLowerCase() === "clients") {
          const titlePart1 = route[1].toUpperCase();
          document.title = `${titlePart1} - ${titlePart2.join(" ")} - ${"SPTECH - IT Solutions"}`;
        } else {
          document.title = `${titlePart2.join(" ")} - ${"SPTECH - IT Solutions"}`;
        }
      } catch (error) {
        document.title = `${"SPTECH - IT Solutions"}`;
      }
    };

    if (!isLoading && user) {
      setTitle();
      setTheme();
      if (
        (isMFAActive && mfaSessionId !== null) ||
        (!isMFAActive && !isMFAForced && mfaSessionId !== null)
      ) {
        // remove redirectUri cookie as its not needed anymore
        Cookies.remove("redirectUri");
      }

      // Set sidenav color based on client ID in the URL pathname
      document.documentElement.scrollTop = 0;
      document.scrollingElement.scrollTop = 0;
      switch (client) {
        case "spt01":
          setSidenavColor(dispatch, "primary");
          break;
        case "mfr01":
          setSidenavColor(dispatch, "primaryLight");
          break;
        case "rwa01":
          setSidenavColor(dispatch, "secondary");
          break;
        case "qex01":
          setSidenavColor(dispatch, "warning");
          break;
        default:
          setSidenavColor(dispatch, "primary");
          break;
      }
    }
  }, [isLoading, pathname]);

  const setupTwoFactor = async () => {
    try {
      const res = await axios.post(
        `${process.env.REACT_APP_APPSPACE_API}/admin/appspace-users/mfa/setup`,
        {},
        {
          headers: { authorization: "Bearer " + accessToken, "Content-Type": "application/json" },
        }
      );
      if (res.data?.secret && res.data?.imageUrl) {
        setSecret(res.data.secret);
        setImageUrl(res.data.imageUrl);
      } else {
        setSecret("");
        setImageUrl("");
      }
    } catch (error) {
      errorAlert("Error setting up MFA!");
      console.error("Error setting up MFA", error);
    }
  };

  const handleVerifyOtp = async () => {
    try {
      const idTokenClaims = await getIdTokenClaims();
      const response = await axios.post(
        `${process.env.REACT_APP_APPSPACE_API}/admin/appspace-users/mfa/validate`,
        { otp },
        {
          headers: { authorization: "Bearer " + accessToken, "Content-Type": "application/json" },
        }
      );
      if (response.data?.verified) {
        Cookies.set("mfaSessionId", idTokenClaims?.sid, { secure: true, sameSite: "strict" });
        // Close dialog and allow access to app
        setMFASessionId(idTokenClaims?.sid);
        try {
          await axios.post(
            `${process.env.REACT_APP_APPSPACE_API}/admin/appspace-users/mfa/session-id`,
            { mfaSessionId: idTokenClaims?.sid },
            {
              headers: {
                authorization: "Bearer " + accessToken,
                "Content-Type": "application/json",
              },
            }
          );
        } catch (error) {
          console.error(error);
        }
      } else {
        errorAlert("Invalid MFA token, please try again");
      }
    } catch (error) {
      console.error("Error verifying OTP", error);
      errorAlert(error);
    } finally {
      setMFASessionIdLoading(false);
    }
  };

  const verifyToken = async () => {
    try {
      const idTokenClaims = await getIdTokenClaims();
      const res = await axios.post(
        `${process.env.REACT_APP_APPSPACE_API}/admin/appspace-users/mfa/verify`,
        { otp, secret },
        {
          headers: { authorization: "Bearer " + accessToken, "Content-Type": "application/json" },
        }
      );
      if (res.data?.verified) {
        customAlert("MFA is now enabled", "success");
        Cookies.set("mfaSessionId", idTokenClaims?.sid, { secure: true, sameSite: "strict" });
        setMFASessionId(idTokenClaims?.sid);
        setIsMFAActive(true);
        try {
          await axios.post(
            `${process.env.REACT_APP_APPSPACE_API}/admin/appspace-users/mfa/session-id`,
            { mfaSessionId: idTokenClaims?.sid },
            {
              headers: {
                authorization: "Bearer " + accessToken,
                "Content-Type": "application/json",
              },
            }
          );
        } catch (error) {
          console.error(error);
        }
      } else {
        errorAlert("Invalid MFA token, please try again");
      }
    } catch (error) {
      console.error("Error verifying token", error);
      errorAlert("Error verifying MFA token");
    } finally {
      setMFASessionIdLoading(false);
    }
  };

  const getUserHomepage = (allRoutes, userPermissions) => {
    let homepage = process.env.REACT_APP_HOMEPAGE;
    for (let route of allRoutes) {
      if (homepage !== process.env.REACT_APP_HOMEPAGE) return homepage;
      else if (route.route && userPermissions != null && Array.isArray(userPermissions)) {
        if (route.permissions) {
          try {
            const allowedPermissions = route.permissions.filter((p) => userPermissions.includes(p));
            if (allowedPermissions.length > 0) homepage = route.route;
          } catch (error) {
            // throws error if route.permissions is not an array
            console.error(error);
          }
        } else homepage = route.route;
      } else if (route.collapse) homepage = getUserHomepage(route.collapse, userPermissions);
    }
    return homepage;
  };

  const getAuthorizedRoutes = (allRoutes, userPermissions) => {
    return allRoutes.map((route) => {
      if (route.collapse) {
        return getAuthorizedRoutes(route.collapse, userPermissions);
      } else if (
        userPermissions != null &&
        Array.isArray(userPermissions) &&
        route.route &&
        route.permissions
      ) {
        const allowAccess = route.permissions.filter((p) => userPermissions.includes(p));
        if (allowAccess.length > 0) {
          if (userHomepage === process.env.REACT_APP_HOMEPAGE) {
            setUserHomepage(route.route);
          }
          return <Route exact path={route.route} element={route.component} key={route.key} />;
        }
      } else if (route.route) {
        return <Route exact path={route.route} element={route.component} key={route.key} />;
      } else return null;
    });
  };

  const getGeneralRoutes = (allRoutes) =>
    allRoutes.map((route) => {
      if (route.collapse) {
        return getGeneralRoutes(route.collapse);
      } else if (route.route) {
        return <Route exact path={route.route} element={route.component} key={route.key} />;
      } else return null;
    });

  const configsButton = (
    <MDBox
      display="flex"
      justifyContent="center"
      alignItems="center"
      width="3rem"
      height="3rem"
      bgColor={darkMode ? "white" : "dark"}
      shadow="sm"
      borderRadius="50%"
      position="fixed"
      right="2rem"
      bottom="2rem"
      zIndex={99}
      color={darkMode ? "dark" : "white"}
      sx={{ cursor: "pointer" }}
      onClick={handleDarkMode}
    >
      {darkMode ? (
        <Icon fontSize="medium" color="inherit">
          light_mode
        </Icon>
      ) : (
        <Icon fontSize="medium" color="inherit">
          dark_mode
        </Icon>
      )}
    </MDBox>
  );

  return (
    <ThemeProvider theme={darkMode ? themeDark : theme}>
      <CssBaseline />
      {/* Snackbar for Notifications */}
      <CustomSnackbarAlerts ref={childRef} />
      {!isMFAActive && isMFAForced ? (
        <Dialog
          open={true}
          PaperProps={{
            component: "form",
            onSubmit: (event) => {
              event.preventDefault();
              verifyToken();
            },
          }}
        >
          <DialogTitle>
            <Grid container justifyContent={"center"}>
              <Grid item>
                <MDTypography color="dark" variant="h5">
                  {`Setup Two-Factor Authentication`}
                </MDTypography>
              </Grid>
            </Grid>
          </DialogTitle>
          <DialogContent>
            <Grid container alignItems={"center"} justifyContent={"center"}>
              <Grid item sm={12} textAlign={"center"}>
                <MDTypography color="dark" variant="body" fontSize="16px" fontWeight="regular">
                  {`Please set up Two-Factor Authentication to continue using the app.`}
                </MDTypography>
              </Grid>
              <Grid item textAlign={"center"} sm={12}>
                <br />
                <p>Scan this QR code with your Authenticator app</p>
              </Grid>
              <Grid item textAlign={"center"} sm={12}>
                {imageUrl ? (
                  <img src={imageUrl} alt="QR Code" />
                ) : (
                  <MDTypography color="dark" variant="body" fontSize="16px" fontWeight="regular">
                    {`Loading QR Code...`}
                  </MDTypography>
                )}
              </Grid>
              <Grid item textAlign={"center"} xs={12} sm={5}>
                <TextField
                  autoFocus
                  sx={{ width: "200px" }}
                  margin="dense"
                  variant="outlined"
                  inputProps={{ type: "text" }}
                  name={`Authenticator-otp-verification`}
                  label="Enter the code from the app"
                  value={otp}
                  onChange={(e) => setOTP(e.target.value)}
                />
              </Grid>
              <Grid item textAlign={"center"} xs={12} sm={4}>
                <MDButton sx={{ my: "16px" }} variant="gradient" color="success" type="submit">
                  Verify Code
                </MDButton>
              </Grid>
              <Grid item textAlign={"center"} sm={12} sx={{ mt: "32px" }}>
                <MDTypography variant="button" sx={{ fontSize: "12px" }}>
                  {`In case of any issues please reach out to our 🕵️‍♂️ digital superheroes at `}
                  <a href="mailto:webapps@sptech.com.au">webapps@sptech.com.au</a>
                </MDTypography>
              </Grid>
            </Grid>
          </DialogContent>
        </Dialog>
      ) : isMFAActive && mfaSessionId === null ? (
        <Dialog
          open={true}
          PaperProps={{
            component: "form",
            onSubmit: (event) => {
              event.preventDefault();
              handleVerifyOtp();
            },
          }}
        >
          <DialogTitle>Verify OTP</DialogTitle>
          <DialogContent>
            {/* <br /> */}
            <TextField
              autoFocus
              fullWidth
              margin="dense"
              variant="outlined"
              inputProps={{ type: "text" }}
              name={`Authenticator-otp verification`}
              label="One-Time Password"
              value={otp}
              onChange={(e) => setOTP(e.target.value)}
            />
          </DialogContent>
          <DialogActions>
            <MDButton size="small" variant="gradient" color="success" type="submit">
              Verify
            </MDButton>
          </DialogActions>
        </Dialog>
      ) : (isMFAActive && mfaSessionId !== null && userPerms.length > 0) ||
        (!isMFASessionIdLoading && !isMFAActive && !isMFAForced && userPerms.length > 0) ? (
        <>
          {/* user.email.includes("dashboard") */}
          {user && layout === "dashboard" && !user.email.includes("dashboard") && (
            <>
              <Sidenav
                color={sidenavColor}
                brand={(transparentSidenav && !darkMode) || whiteSidenav ? brandWhite : brandWhite}
                brandName={brandName}
                routes={routes}
                onMouseEnter={handleOnMouseEnter}
                onMouseLeave={handleOnMouseLeave}
              />
              {/* <Configurator /> */}
              {configsButton}
            </>
          )}
          <Suspense fallback={renderLoader()}>
            {/* {layout === "vr" && <Configurator />} */}
            <Routes>
              {getGeneralRoutes(pageRoutes)}
              {getAuthorizedRoutes(routes, userPerms ?? [])}
              {redirectUri ? (
                <Route path="/*" element={<Navigate to={redirectUri} />} />
              ) : (
                <Route
                  path="/*"
                  element={<Navigate to={getUserHomepage(routes, userPerms ?? [])} />}
                />
              )}
            </Routes>
          </Suspense>
        </>
      ) : (
        <CustomLoading />
      )}
    </ThemeProvider>
  );
}
