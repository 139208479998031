import React from "react";
import PropTypes from "prop-types";

import "./styles.css";
export default function CustomLoading({ progress = "", width = "150px", height = "150px" }) {
  return (
    <div className="loading-overlay">
      <div className="progress-text">{progress}</div>
      <div className="spinner" style={{ width, height }}></div>
    </div>
  );
}

CustomLoading.propTypes = {
  progress: PropTypes.string,
  width: PropTypes.string,
  height: PropTypes.string,
};
