// Material Dashboard 2 PRO React base styles
import colors from "assets/theme/base/colors";

// Material Dashboard 2 PRO React helper functions
import rgba from "assets/theme/functions/rgba";

const { primaryLight, white, dark, gradients } = colors;

const flatpickr = {
  ".flatpickr-day:hover, .flatpickr-day:focus, .flatpickr-day.nextMonthDay:hover, .flatpickr-day.nextMonthDay:focus":
    {
      background: rgba(primaryLight.main, 0.28),
      border: "none",
    },

  ".flatpickr-day.today": {
    background: primaryLight.main,
    color: white.main,
    border: "none",

    "&:hover, &:focus": {
      background: `${primaryLight.focus} !important`,
    },
  },

  ".flatpickr-day.selected, .flatpickr-day.selected:hover, .flatpickr-day.nextMonthDay.selected, .flatpickr-day.nextMonthDay.selected:hover, .flatpickr-day.nextMonthDay.selected:focus":
    {
      background: `${gradients.primaryLight.state} !important`,
      color: white.main,
      border: "none",
    },

  ".flatpickr-months .flatpickr-next-month:hover svg, .flatpickr-months .flatpickr-prev-month:hover svg":
    {
      color: `${primaryLight.main} !important`,
      fill: `${primaryLight.main} !important`,
    },
  ".flatpickr-innerContainer": {
    background: `${white.main} !important`,
  },
  ".flatpickr-weekday": {
    color: `${white.main}e0 !important`,
  },
  ".flatpickr-day": {
    color: `${dark.main} !important`,
  },
  ".flatpickr-day:hover": {
    background: `${primaryLight.main} !important`,
  },
  ".flatpickr-day.nextMonthDay, .flatpickr-day.prevMonthDay, .flatpickr-day.flatpickr-disabled": {
    color: `${dark.main}70 !important`,
  },
  ".flatpickr-weekdays, .flatpickr-weekday, .flatpickr-month, .flatpickr-monthDropdown-months, .flatpickr-monthDropdown-month":
    {
      background: `${primaryLight.main} !important`,
    },
};

export default flatpickr;
