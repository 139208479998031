const xDataGrid = {
  styleOverrides: {
    root: {
      "& .MuiDataGrid-overlayWrapper": {
        minHeight: `200px`,
      },
    },
  },
};

export default xDataGrid;
