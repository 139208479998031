// Material Dashboard 2 PRO React base styles
import colors from "assets/theme-dark/base/colors";

const { dark, white } = colors;
const xDataGrid = {
  styleOverrides: {
    root: {
      color: `${white.main}`,
      "& .MuiDataGrid-booleanCell": {
        color: `${white.main}`,
      },
      "& .MuiDataGrid-overlayWrapper": {
        minHeight: `200px`,
      },
      ".MuiDataGrid-row--editing .MuiDataGrid-cell": {
        color: `${white.main}`,
        backgroundColor: `${dark.focus}`,
      },
      ".MuiDataGrid-sortIcon, .MuiDataGrid-filterIcon": {
        color: `${white.main}`,
      },
    },
    panelWrapper: {
      color: `${white.main}`,
      backgroundColor: `${dark.focus}`,
    },
    menu: {
      "& .MuiDataGrid-menuList": {
        color: `${white.main}`,
        backgroundColor: `${dark.focus}`,
      },
    },
    panelContent: {
      backgroundColor: `${dark.focus}`,
    },
    filterForm: {
      backgroundColor: `${dark.focus}`,
    },
    filterFormColumnInput: {
      "& .MuiSvgIcon-root": {
        color: `${white.main}`,
      },
      "& .MuiNativeSelect-select": {
        color: `${white.main}`,
        option: {
          backgroundColor: `${dark.focus}`,
        },
      },
    },
    filterFormOperatorInput: {
      "& .MuiSvgIcon-root": {
        color: `${white.main}`,
      },
      "& .MuiNativeSelect-select": {
        color: `${white.main}`,
        option: {
          backgroundColor: `${dark.focus}`,
        },
      },
    },
    filterFormDeleteIcon: {
      "& .MuiIconButton-root": {
        color: `${white.main}`,
      },
    },
    editInputCell: {
      color: `${white.main}`,
    },
    columnHeader: {
      color: `${white.main}`,
      "& .MuiIconButton-root": {
        color: `${white.main}`,
      },
    },
    row: {
      "& .row--editing": {
        color: `${white.main}`,
        backgroundColor: `${dark.main}`,
      },
      "& .MuiDataGrid-cell--editing": {
        "& .MuiInputBase-root, .MuiSelect-select": {
          height: `100%`,
        },
      },
    },
  },
};

export default xDataGrid;
