// Material Dashboard 2 PRO React base styles
import colors from "assets/theme-dark/base/colors";

const { white } = colors;

const alert = {
  styleOverrides: {
    root: {
      color: `${white.main} !important`,
    },
  },
};

export default alert;
