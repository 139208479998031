import React, { useState, forwardRef, useImperativeHandle } from "react";

// @material-ui core components
import { Alert, Snackbar } from "@mui/material";

const CustomSnackbarAlerts = forwardRef((props, ref) => {
  const [snackbarMessage, setSnackbarMessage] = useState("");
  const [snackbarAlertColor, setSnackbarAlertColor] = useState("info");
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const handleSnackbarClose = (event, reason) => {
    if (reason === "clickaway") return;
    setSnackbarOpen(false);
  };

  // create our ref object
  const publicRef = {
    errorAlert: (error) => {
      let errorMessage = error;
      if (error.response && error.response.data) errorMessage = error.response.data.message;
      else if (error.message) errorMessage = error.message;
      else if (typeof errorMessage !== "string") {
        errorMessage = "Error!";
        console.error(error);
      }

      if (errorMessage === "jwt expired") {
        errorMessage += ". Please refresh the page and try again.";
      }
      setSnackbarAlertColor("error");
      setSnackbarMessage(errorMessage);
      setSnackbarOpen(true);
    },
    errorAlertForBufferResponse: (error) => {
      let errorMessage = error;
      if (error.response && error.response.data) {
        const x = JSON.parse(String.fromCharCode(...new Uint8Array(error.response.data)));
        if (x.message) errorMessage = x.message;
        else errorMessage = "Error: Failed to download the file!";
      } else if (error.message) {
        errorMessage = error.message;
      } else if (typeof errorMessage !== "string") {
        errorMessage = "Error!";
        console.error(error);
      }

      if (errorMessage === "jwt expired") {
        errorMessage += ". Please refresh the page and try again.";
      }

      setSnackbarAlertColor("error");
      setSnackbarMessage(errorMessage);
      setSnackbarOpen(true);
    },
    customAlert: (message, severity = "info") => {
      if (message === "jwt expired") {
        message += ". Please refresh the page and try again.";
      }
      setSnackbarAlertColor(severity);
      setSnackbarMessage(message);
      setSnackbarOpen(true);
    },
    alertClose: () => {
      setSnackbarOpen(false);
    },
  };
  // pass the ref and a function that returns our object
  useImperativeHandle(ref, () => publicRef);
  return (
    <Snackbar
      anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
      open={snackbarOpen}
      autoHideDuration={7000}
      onClose={handleSnackbarClose}
    >
      <Alert
        onClose={handleSnackbarClose}
        sx={{ width: "100%" }}
        variant="filled"
        severity={snackbarAlertColor}
      >
        {snackbarMessage}
      </Alert>
    </Snackbar>
  );
});

CustomSnackbarAlerts.displayName = "CustomSnackbarAlerts";

export default CustomSnackbarAlerts;
