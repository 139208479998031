// Material Dashboard 2 PRO React base styles
import colors from "assets/theme-dark/base/colors";

const { dark, white } = colors;
const calendarPicker = {
  styleOverrides: {
    root: {
      color: `${white.main} !important`,
      backgroundColor: `${dark.focus} !important`,
      "& .MuiSvgIcon-root": {
        color: `${white.main} !important`,
      },
    },
  },
};

export default calendarPicker;
