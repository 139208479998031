import React from "react";
import { createRoot } from "react-dom/client";
import { BrowserRouter } from "react-router-dom";

import App from "App";
import Auth0ProviderWithHistory from "components/CustomComponents/auth0-provider-with-history";

// Material Dashboard 2 PRO React Context Provider
import { MaterialUIControllerProvider } from "context";

const container = document.getElementById("root");
const root = createRoot(container); // createRoot(container!) if you use TypeScript
root.render(
  <React.StrictMode>
    <BrowserRouter>
      <MaterialUIControllerProvider>
        <Auth0ProviderWithHistory>
          <App />
        </Auth0ProviderWithHistory>
      </MaterialUIControllerProvider>
    </BrowserRouter>
  </React.StrictMode>
);
