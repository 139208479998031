// Material Dashboard 2 PRO React base styles
import colors from "assets/theme-dark/base/colors";

// Material Dashboard 2 PRO React helper functions

const { white, error, success, primary, secondary, info, warning } = colors;
const chip = {
  styleOverrides: {
    outlinedDefault: {
      color: `${white.main} !important`,
    },
    filledDefault: {
      color: `${white.main} !important`,
      backgroundColor: `${white.main}59 !important`,
    },
    filled: {
      "& .MuiChip-colorPrimary": {
        color: `${white.main} !important`,
        backgroundColor: `${primary.main} !important`,
      },
      "& .MuiChip-colorSecondary": {
        color: `${white.main} !important`,
        backgroundColor: `${secondary.main} !important`,
      },
      "& .MuiChip-colorError": {
        color: `${white.main} !important`,
        backgroundColor: `${error.main} !important`,
      },
      "& .MuiChip-colorInfo": {
        color: `${white.main} !important`,
        backgroundColor: `${info.main} !important`,
      },
      "& .MuiChip-colorSuccess": {
        color: `${white.main} !important`,
        backgroundColor: `${success.main} !important`,
      },
      "& .MuiChip-colorWarning": {
        color: `${white.main} !important`,
        backgroundColor: `${warning.main} !important`,
      },
    },
  },
};

export default chip;
