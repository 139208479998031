import React from "react";
import { useEffect } from "react";
import { Auth0Provider } from "@auth0/auth0-react";
import Cookies from "js-cookie";
import PropTypes from "prop-types";

const Auth0ProviderWithHistory = ({ children }) => {
  const domain = process.env.REACT_APP_AUTH0_DOMAIN;
  const clientId = process.env.REACT_APP_AUTH0_CLIENT_ID;
  const audience = process.env.REACT_APP_AUTH0_AUDIENCE;

  useEffect(() => {
    // auth0 will always redirect to the origin url
    // store the remaining path to a cookie whenever auth0 is called
    const addRedirectURICookie = () => {
      if (window.location.pathname != `/`) {
        const hash = typeof window.location.hash === "string" ? window.location.hash : "";
        Cookies.set("redirectUri", `${window.location.pathname}${hash}`, {
          secure: true,
          sameSite: "strict",
        });
      }
    };
    addRedirectURICookie();
  }, []);
  return (
    <Auth0Provider
      domain={domain}
      clientId={clientId}
      authorizationParams={{
        redirect_uri: window.location.origin,
        audience,
        scope: "openid profile email",
      }}
    >
      {children}
    </Auth0Provider>
  );
};

export default Auth0ProviderWithHistory;

Auth0ProviderWithHistory.propTypes = {
  children: PropTypes.node.isRequired,
};
