// Material Dashboard 2 PRO React base styles
import colors from "assets/theme/base/colors";

const { light } = colors;

const alert = {
  styleOverrides: {
    filled: {
      color: `${light.main} !important`,
    },
  },
};

export default alert;
